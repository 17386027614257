import {Cart, CartItem, PaymentGateway} from "~/types/objects";
import eventsTracker from "~/composables/EventsTracker";
import {useNuxtApp} from '#app';

const {parseCartProduct} = useProductHelpers();


export const useCartStore = defineStore("CartStore", {
  state: () => ({
    cart: [] as Array | null,
    serverCart: {} as Cart,
    showCart: false, // This is not persisted
    couponCode: "",
    isShowingCart: false,
    isUpdatingCart: false,
    productsAdded: false,
    isUpdatingCoupon: false,
    paymentGateways: [] as PaymentGateway[],
    upsaleTrunk: {} as Object,
    showUpsalePopup: false,
    redirectToCheckout: false,
    addedItems: [],
  }),
  getters: {
    itemsInCart: (state) => {
      return state.addedItems.length || 0;
    },
    isEmptyCart: (state) => {
      if (state.cart?.contents?.nodes.length > 0) return false;
      return false;
    },
    hasTrunkInCart: (state) => {
      if (!state.cart) return false;
      const products = state.addedItems || [];
      let has_mat_products = false;
      let has_trunk_products = false;
      _Each(products as CartItem[], (product) => {
        const is_mat_product = typeof product.make !== "undefined" ? true : false;
        if (is_mat_product) {
          has_mat_products = true;
          const selected_options = product?.data?.selected_options;
          if (typeof selected_options !== "undefined" && selected_options.value == "trunk1") {
            has_trunk_products = true;
          }
        }
      });
      return has_trunk_products;
    },
    cartTotal: (state) => {
      return _Reduce(
        state.addedItems,
        (acc, item) => {
          if (item.data?.trunk_discount == 1 && item.fullPrice) {
            return acc + item.price * 1 + item.fullPrice * (item.quantity - 1);
          } else {
            return acc + item.price * item.quantity;
          }
        },
        0
      );
    },
  },
  actions: {
    toggleCart() {
      this.showCart = !this.showCart;
    },
    setAddedItems(items : Object[]) {
        this.addedItems = items;
    },
    setCart(cart: Cart) {
      this.cart = cart;
      if (cart?.contents?.nodes?.length == 0) {
        this.addedItems = [];
      }
    },
    updateCart(cart: Cart) {
      if (_IsEmpty(this.cart)) {
        this.cart = cart;
        return;
      }
    },
    updateQuantity(sku: String, quantity: number) {
      const index = _FindIndex(this.addedItems, { sku: sku });
      if (quantity < 1) {
        this.addedItems.splice(index, 1);
        return;
      }
      this.addedItems[index].quantity = quantity;

    },
    async syncCartFromServer(cart: Cart) {
      if (_IsEmpty(cart)) {
        return false;
      }
      this.serverCart = cart;
      let tmpCart: Cart = cart;
      const removeItems = <String[]>[];
      const syncQuantities = [];
      const itemsToAdd = [] as CartItemInput[];
      if (cart.contents?.nodes?.length > 0) {
        cart.contents?.nodes.forEach((product) => {
          const key = product.key;
          const productId = product.product?.node.databaseId;
          const sku = _Find(product.extraData, { key: "sku" });
          if (sku && sku?.value) {
            const matColor = _Find(product.extraData, { key: "mat_color" });
            const matBorderColor = _Find(product.extraData, { key: "mat_border_color" });

            const index = _FindIndex(this.addedItems, (p) => {
              if (!_IsEmpty(matColor) && !_IsEmpty(matBorderColor)) {
                const unJsonColor = JSON.parse(matColor.value);
                const unJsonBorder = JSON.parse(matBorderColor.value);
                return (
                  p.sku === sku.value &&
                  p.data.mat_color.value === unJsonColor.value &&
                  p.data.mat_border_color.value === unJsonBorder.value
                );
              }
              if (!_IsEmpty(matColor) && _IsEmpty(matBorderColor)) {
                const unJsonColor = JSON.parse(matColor.value);
                return p.sku === sku.value && p.data.mat_color.value === unJsonColor.value;
              } else {
                return p.sku === sku.value;
              }
            });
            if (index > -1) {
              console.log('product',key,this.addedItems[index].key,product);
              if (_IsEmpty(this.addedItems[index].key)){
                this.addedItems[index].key = key;
              }
              if (  this.addedItems[index].key == key && this.addedItems[index].quantity !== product.quantity) {
                syncQuantities.push({ key: key, quantity: this.addedItems[index].quantity });
              }
            } else {
              removeItems.push(key);
            }
          } else {
            const index = _FindIndex(this.addedItems, { id: productId });
            if (index > -1) {
              this.addedItems[index].key = key;
              if (this.addedItems[index].quantity !== product.quantity) {
                syncQuantities.push({ key: key, quantity: this.addedItems[index].quantity });
              }
            } else {
              removeItems.push(key);
            }
          }
        });

        if (removeItems.length > 0) {
          try {
            const { removeItemsFromCart } = await GqlRemoveItems({ key: removeItems });
            tmpCart = removeItemsFromCart?.cart;
          } catch (e) {
            try {
              const { emptyCart } = await GqlEmptyCart();
              tmpCart = emptyCart?.emptyCart;
            } catch (e) {
              console.log("error: ", e);
            }
          }
        }
        if (syncQuantities.length > 0) {
          console.log('sync quantityes',syncQuantities);
          try {
            const { updateItemQuantities } = await GqlBulkUpdateQuantities({ items: syncQuantities });
            tmpCart = updateItemQuantities?.cart;
          } catch (e) {
            console.log("error: ", e);
          }
        }
      }
      this.addedItems.forEach((product) => {
        if (_IsEmpty(product.key) || _FindIndex(cart.contents?.nodes, { key: product.key }) === -1) {
          const cloned = _CloneDeep(product);
          if (!_IsEmpty(cloned.data?.selected_options)) {
            cloned.data.selected_options = cloned.data.selected_options.value;
          }
          const temp = {
            productId: cloned.id,
            quantity: cloned.quantity,
            extraData: JSON.stringify(cloned.data),
          };
          itemsToAdd.push(temp);
        }
      });
      if (this.addedItems.length == 0) {
        try {
          const emptyCart = await GqlEmptyCart();
          tmpCart = emptyCart?.emptyCart;
        } catch (e) {
          console.log("error: ", e);
        }
      }
      if (itemsToAdd.length > 0) {
        const { addCartItems } = await GqlAddToCartItems({ input: { items: itemsToAdd } });
        tmpCart = addCartItems?.cart;
        // this.syncCartFromServer(addCartItems?.cart);
      }
      this.cart = tmpCart;
      return true;
    },
    async fillCart(){

        try{
          const { emptyCart } = await GqlEmptyCart();
        }
        catch (e) {
          console.log(e.message);
        }

      const fillCartInput = {} as FillCartInput;
      const itemsToAdd = [] as CartItemInput[];
      const coupons = [] as string[];
      const shippingMethods = ["flat_rate:1"];
      this.addedItems.forEach((product) => {
        const cloned = _CloneDeep(product);
        if (!_IsEmpty(cloned.data?.selected_options)) {
          cloned.data.selected_options = cloned.data.selected_options.value;
        }
        const temp = {

          productId: parseInt( cloned.id),
          quantity: parseInt(cloned.quantity),
          extraData: JSON.stringify(cloned.data),
        };
        itemsToAdd.push(temp);
      });
      if (this.couponCode){
        coupons.push(this.couponCode);
      }
      fillCartInput.items = itemsToAdd;
      fillCartInput.coupons = coupons;
      fillCartInput.shippingMethods = shippingMethods;
      const {fillCart} = await GqlFillCart( { cartFill: fillCartInput });
      this.cart = fillCart?.cart;
    },
    async updateServerCart() {
      if (!this.isUpdatingCart) {
        this.isUpdatingCart = true;
        // if (!this.cart?.isEmpty){
        //   console.log(this.cart);
        // try{
        //   const { emptyCart } = await GqlEmptyCart();
        // }
        // catch (e) {
        //   console.log(e.message);
        // }
        // }

        // const data = await GqlGetCart();
        // const finish = await this.syncCartFromServer(data?.cart);
        // if (this.couponCode){
        //   const {applyCoupon} = await GqlApplyCoupon({ code: this.couponCode });
        //   this.cart = applyCoupon?.cart;
        // }
        await this.fillCart();
        this.isUpdatingCart = false;
      }
      return true;
    },
    async setNewCart() {
      this.isupdatingCart = true;
      let data = null;
      try {
        data = await GqlEmptyCart();
      } catch (e) {
        console.log("error: ", e?.message);
      }
      const finish = await this.syncCartFromServer(data?.emptyCart || {});
      this.isupdatingCart = false;
      return finish;
    },
    addToCart(products: []) {
      const { addToCartEvent } = eventsTracker();
      products.forEach((product) => {
        const tmp = parseCartProduct(product);
        const find = _FindIndex(this.addedItems, function (o) {
          if (!_IsEmpty(o.data?.selected_options)) {
            return o.sku === tmp.sku && o.data.selected_options === tmp.data.selected_options;
          }
          if(!_IsEmpty(o.data?.keychain)){
            return  _IsEqual(o.data.keychain, tmp.data.keychain) && _IsEqual(o.data?.mat_color, tmp.data?.mat_color);
          }
          return o.sku === tmp.sku;
        });
        console.log('add to cart find: ',find);
        if (find === -1) {
          this.addedItems.push(tmp);
          addToCartEvent({ id: tmp.id, price: tmp.price, name: tmp.product.name, currency: "ILS" });
        } else {
          this.addedItems[find].quantity = tmp.quantity;
        }
      });
      // this.addedItems = [];
      //this.addedItems = [...this.addedItems, ...products];
    },
    async cartUpdate() {
      const data = await GqlGetCart();
      this.setCart(data?.cart);
    },
  },
  persist: true,
});

<template>
  <NuxtLayout>
    <NuxtPage/>
  </NuxtLayout>

</template>
<script setup lang="ts">

const {setGlobalOptions, setMenus, setCarFilter, setCarFilter3d, setStock,setMadeInIsraelTag} = useGlobalStore();
const {refreshCart, emptyCart} = useCart();
const config = useRuntimeConfig();

const {setCart,setAddedItems} = useCartStore();
const {addedItems} = storeToRefs(useCartStore());
const {clearAllCookies} = useHelpers();
const nuxtApp = useNuxtApp();
const loading = ref(false);
const route = useRoute();




const allFilters = await Promise.all([wpFetch("cars-filter"), wpFetch("cars-filter/3d")]);
const cars = allFilters[0];
const cars_3d = allFilters[1];



try {
  const parsed = JSON.parse(allFilters[0]);
  setCarFilter(parsed);
} catch (e) {
  setCarFilter(cars);
}

try {
  const parsed_3d = JSON.parse(allFilters[1]);
  setCarFilter3d(parsed_3d);
} catch (e) {
  setCarFilter3d(cars_3d);
}

// const {data, pending} = await useFetch('/api/getGlobals');
const {data} =  await useFetch(`${config.public.BACK_END_URL}/wp-json/wp/v2/globalsettings`);

const globalData = (data?.value);

useFetch(`${config.public.BACK_END_URL}/wp-json/wp/v2/checkstock`).then(({data}) => {
  setStock(data.value);
});

// $fetch('/api/getStockStatus').then((data) => {
  
//   setStock(data);
// });

// try {
// const {productTag} = await GqlGetMadeInIsrael();
//   if (productTag){
//     setMadeInIsraelTag(productTag);
//   }
// }
// catch (e) {
//   console.log(e);
// }


if (globalData) {
  setMenus(globalData?.menus?.nodes);
  setGlobalOptions(globalData?.themeGeneralSettings?.globalOptions);
  setMadeInIsraelTag(globalData?.productTag);
}

useHead({
  script: [

    {
      //     innerHTML: `
      //     (function () {
      //   var s = document.createElement("script");
      //   var h = document.querySelector("head") || document.body;
      //   s.src = "https://acsbapp.com/apps/app/dist/js/app.js";
      //   s.async = true;
      //   s.onload = function () {
      //     acsbJS.init({
      //       statementLink: "",
      //       footerHtml: "",
      //       hideMobile: false,
      //       hideTrigger: false,
      //       disableBgProcess: false,
      //       language: " he ",
      //       position: "right",
      //       leadColor: "#146FF8",
      //       triggerColor: "#146FF8",
      //       triggerRadius: "50%",
      //       triggerPositionX: "right",
      //       triggerPositionY: "bottom",
      //       triggerIcon: "people",
      //       triggerSize: "bottom",
      //       triggerOffsetX: 20,
      //       triggerOffsetY: 20,
      //       mobile: {
      //         triggerSize: "small",
      //         triggerPositionX: "right",
      //         triggerPositionY: "bottom",
      //         triggerOffsetX: 10,
      //         triggerOffsetY: 10,
      //         triggerRadius: "20",
      //       },
      //     });
      //   };
      //   h.appendChild(s);
      // })();
      //     `
    }
  ],
});

// onMounted(() => {
//   if (process.client && window){
//     window.addEventListener('storage', (e) => {
//       if (e.key === 'CartStore') {
//         const cart = JSON.parse(e.newValue);
//         const items = cart.addedItems;
//         // setAddedItems(addedItems);
//         _Merge(addedItems.value, items);
//       }
//     });
//   }
// })
if (process.client) {
  if (!_IsEmpty(route.query?.start_chat)) {
    if (route.query.start_chat) {
      const whatsapp = globalData?.themeGeneralSettings?.globalOptions?.whatsapp;
      if (whatsapp) {
        window.top.location = `https://wa.me/972${whatsapp}?text=${route.query.start_chat}`;
      }
    }
  }
}
</script>
<style lang="scss">

.v-dialog {
  z-index: inherit;
}

html,
body {
  font-family: "Assistant", sans-serif !important;
  font-size: 18px;
  text-align: right;
  color: #131820;
  line-height: 1.5;
  overflow-x: hidden;
  overflow-y: auto;
  letter-spacing: normal !important;

  &.with-fancybox {

    overflow: hidden;

  }
}

.v-btn {
  letter-spacing: normal !important;
  font-weight: 600 !important;
}

.el-dialog {
  --el-dialog-content-font-size: 18px;
}

html {
  scroll-behavior: smooth;
  &.v-overlay-scroll-blocked {
    position: initial !important;
    top: initial !important;
    left: initial !important;
    width: initial !important;
    height: initial !important;
    overflow: hidden !important;
  }
}

.nuxt-icon svg {
  width: 100% !important;
  height: auto !important;
  margin-bottom: 0 !important;
}

.va-input-wrapper {
  &__container {
    width: 100%;
  }

  &__field {
    border: 0;
  }
}


//.page-enter-active,
//.page-leave-active {
//  transition: all 0.4s;
//}
//
//.page-enter-from,
//.page-leave-to {
//  opacity: 0;
//  filter: blur(1rem);
//}

.v-overlay__scrim {
  opacity: 0.8 !important;
}

.tooltip {
  font-size: 20px;
}

.el-popper {
  font-size: 20px;
}

.carousel {
  $parent: &;

  &--rtl {
    #{$parent}__prev {
      right: -60px;
      width: 50px;
      @media screen and (max-width: 800px) {
        display: none;
      }
    }

    #{$parent}__next {
      left: -60px;
      width: 50px;
      @media screen and (max-width: 800px) {
        display: none;
      }
    }
  }

  //--vc-icn-width: 60px;
  //--vc-icn-heigth: 60px;
}

.page-transiton-enter-active {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9;
}

.page-transiton-leave-active {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* z-index:4; */
}

.page-left-enter-active,
.page-right-enter-active,
.page-left-leave-active,
.page-right-leave-active {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  transition: all 150ms linear;
}

.page-left-enter-from, .page-right-leave-to {
  transform: translateX(100%);
}

.page-left-leave-to, .page-right-enter-from {
  transform: translateX(-100%);
}

.page-left-enter-to, .page-right-enter-to {
  transform: translateX(0);
}

.layout-enter-active,
.layout-leave-active {
  transition: all 0.4s;
}

.layout-enter-from,
.layout-leave-to {
  filter: grayscale(1);
}

.v-overlay--active {
  z-index: 400 !important;
}

.gallery-btn {
  transform: translate(75%, 0%) !important;
  @media all and (max-width: 800px) {
    transform: translate(0%, 200%) !important;
  }
}
.made-in-israel-tag{
  width: 60px !important;
  position: absolute;
  z-index: 20;
  left: 10px;
  top: 10px;
  &--row{
    max-width: 40px!important;
    left: 20px;
  }
}

.form_message{
  text-align: center;
  color: green;
  border: 1px solid;
  padding: 5px;
  margin-top: 10px;
}
.selected-keychain-image{
  height: 120px;
  width: 560px;
  max-width: 100%;
  background-size: 100%;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  &.car-plate-cahal,
  &.car-plate-police{
    .side-text{
      text-align: left;
      color: #fff;
    }
  }
  &.car-plate-text{
    .side-text{
      font-size: 50px;
      @media screen and (max-width: 800px) {
        font-size: 40px;

      }
    }
  }
  //.side-text {
  //  max-width: 100%;
  //  padding-left: 10%;
  //  position: absolute;
  //  right: 50%;
  //  top: 50%;
  //  font-size: 80px;
  //  font-weight: 900;
  //  transform: translate(50%, -50%);
  //  letter-spacing: 4px;
  //  line-height: 1;
  //  white-space: nowrap;
  //  text-overflow: clip;
  //}
  .side-text {
    text-align: center;
    max-width: 100%;
    width: 100%;
    padding-left: 12%;
    font-size: 80px;
    font-weight: 900;
    letter-spacing: 4px;
    line-height: 1;
    @media screen and (max-width: 800px) {
      font-size: 55px;
    }
    //white-space: nowrap;
    //text-overflow: clip;
  }
}
</style>

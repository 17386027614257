export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1"},{"charset":"utf-8"},{"name":"google-site-verification","content":"TQ6P974UVy68heePXfNT199VvZdoKtHW9wXeuocohyA"},{"name":"facebook-domain-verification","content":"dku00i0n24je7zphgnfzxwo4pa2iih"},{"name":"apple-mobile-web-app-capable","content":"yes"}],"link":[{"rel":"icon","type":"image/png","href":"https://admin.sasa.co.il/wp-content/uploads/2021/01/cropped-sasa-logo-seo.png"},{"rel":"apple-touch-icon","href":"https://admin.sasa.co.il/wp-content/uploads/2021/01/cropped-sasa-logo-seo.png"}],"style":[],"script":[{"src":"https://cdn.jsdelivr.net/npm/sticksy/dist/sticksy.min.js","defer":true},{"src":"https://cdn.adscale.com/static/ecom_js/sasa.co.il/adscale_purchase.js","id":"adScale","defer":true}],"noscript":[],"htmlAttrs":{"lang":"he-IL","dir":"rtl"}}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appRootAttrs = {"id":"__nuxt"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const appSpaLoaderTag = "div"

export const appSpaLoaderAttrs = {"id":"__nuxt-loader"}

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink","prefetch":true,"prefetchOn":{"visibility":true}}

export const asyncDataDefaults = {"value":null,"errorValue":null,"deep":true}

export const resetAsyncDataToUndefined = true

export const nuxtDefaultErrorValue = null

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000

export const multiApp = false

export const chunkErrorEvent = "vite:preloadError"

export const crawlLinks = false

export const spaLoadingTemplateOutside = false
import {Fancybox} from "@fancyapps/ui";
import { storeToRefs } from "pinia";
// import AOS from 'nuxt-aos';
import "@fancyapps/ui/dist/fancybox/fancybox.css";

// import 'aos/dist/aos.css'; // You can also use <link> for styles

export default defineNuxtPlugin(async () => {

    //useGqlCors({ credentials: "include", mode: "no-cors" });
    // useGqlCors({ credentials: "include", mode: "no-cors", client: "get" });
    const {filterPop} = storeToRefs(useGlobalStore());
    const {logoutUser } = useAuth();
    const { clearAllCookies,clearAllLocalStorage } = useHelpers();
    if (process.client) {
        //sessionToken.value = customer?.sessionToken;
        const sessionToken = useCookie("woocommerce-session");
        if (sessionToken.value) {
            useGqlHeaders({"woocommerce-session": `Session ${sessionToken.value}`}, "actions");

        }
        // if (customer?.sessionToken) {
        //     sessionToken.value = customer?.sessionToken;
        //     useGqlHeaders({"woocommerce-session": `Session ${sessionToken.value}`}, "actions");
        // }
        // Wait for the user to interact with the page before refreshing the cart, this is helpful to prevent excessive requests to the server
        let initialised = false;
        const eventsToFireOn = [
            "mousedown",
            "keydown",
            "touchstart",
            "scroll",
            "wheel",
            "click",
            "resize",
            "mousemove",
            "mouseover",
        ];

        eventsToFireOn.forEach((event) => {
            window.addEventListener(
                event,
                async () => {
                    // We only want to execute this code block once, so we return if initialised is truthy.
                    if (initialised) return;
                    initialised = true;

                    const { refreshCart } = useCart();
                    const success = await refreshCart();

                    useGqlError((err: any) => {
                        const serverErrors = ['The iss do not match with this server', 'Invalid session token'];
                        if (serverErrors.includes(err?.gqlErrors?.[0]?.message)) {
                            clearAllCookies();
                            clearAllLocalStorage();
                            window.location.reload();
                        }
                    });


                    if (!success) {
                        clearAllCookies();
                        clearAllLocalStorage();
                        // Add a new cookie to prevent infinite reloads
                        const reloadCount = useCookie("reloadCount");
                        if (!reloadCount.value) {
                            reloadCount.value = "1";
                        } else {
                            return;
                        }

                        // Log out the user
                        const { logoutUser } = useAuth();
                        await logoutUser();

                        if (!reloadCount.value) window.location.reload();
                    }



                    Fancybox.bind("[data-fancybox]", {
                        Thumbs: {
                            type: "classic",
                        },
                        on: {
                            done: (fancybox, slide) => {
                                const wrapper = document.createElement("div");
                                const button = (document.createElement(
                                    "div"
                                ).innerHTML = `<div class="bt_red text-center mx-auto"><a href="javascript:void(0)" onclick="filterPop = true">לרכישה</a></div>`);
                                wrapper.classList.add("wrapper", "gallery-btn");
                                wrapper.innerHTML = button;

                                wrapper.style.position = "absolute";
                                wrapper.style.width = "100%";
                                if (process.client && window && window.outerWidth > 800) {
                                    wrapper.style.top = "50%";
                                    wrapper.style.right = "0%";
                                    wrapper.style.transform = "translate(100%,0%)";
                                }
                                if (process.client && window && window.outerWidth < 800) {
                                    wrapper.style.bottom = "0%";
                                    wrapper.style.right = "0%";
                                    wrapper.style.transform = "translate(0%,200%)";
                                }
                                slide.contentEl.append(wrapper);
                                wrapper.addEventListener("click", () => {
                                    setTimeout(() => {
                                        filterPop.value = true;
                                    }, 300);

                                    fancybox.close();

                                });

                                // slide.contentEl.innerHTML = button;
                            },
                        },
                        // Your options go here
                    });
                    // AOS.init();
                },
                {once: true}
            );
        });
    }
});


if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _91_46_46_46slug_93Qv3uKeTLMXMeta } from "/vercel/path0/pages/[...slug].vue?macro=true";
import { default as _404lZJpwxvtooMeta } from "/vercel/path0/pages/404.vue?macro=true";
import { default as cart3AXNnOUus9Meta } from "/vercel/path0/pages/cart.vue?macro=true";
import { default as _91slug_93zr7ltafwtNMeta } from "/vercel/path0/pages/category/[slug].vue?macro=true";
import { default as indexraiq4bUafSMeta } from "/vercel/path0/pages/checkout/index.vue?macro=true";
import { default as _91order_id_934QhPERmQPzMeta } from "/vercel/path0/pages/checkout/order-pay/[order_id].vue?macro=true";
import { default as _91order_id_93RMTRWWWPvIMeta } from "/vercel/path0/pages/checkout/order-received/[order_id].vue?macro=true";
import { default as hfd_45send_45ordersCFa1P1qt22Meta } from "/vercel/path0/pages/hfd-send-orders.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as index9uLfdLkXniMeta } from "/vercel/path0/pages/my-account/index.vue?macro=true";
import { default as login7OJxvpy2mgMeta } from "/vercel/path0/pages/my-account/login.vue?macro=true";
import { default as lost_45passwordbdY3qOPskeMeta } from "/vercel/path0/pages/my-account/lost-password.vue?macro=true";
import { default as _91_46_46_46product_93SBP4NZMwiSMeta } from "/vercel/path0/pages/product/[...product].vue?macro=true";
import { default as component_45stub5X4Ei38PMgMeta } from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stub5X4Ei38PMg } from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    meta: _91_46_46_46slug_93Qv3uKeTLMXMeta || {},
    component: () => import("/vercel/path0/pages/[...slug].vue")
  },
  {
    name: "404",
    path: "/404",
    component: () => import("/vercel/path0/pages/404.vue")
  },
  {
    name: "cart",
    path: "/cart",
    component: () => import("/vercel/path0/pages/cart.vue")
  },
  {
    name: "category-slug",
    path: "/category/:slug()",
    component: () => import("/vercel/path0/pages/category/[slug].vue")
  },
  {
    name: "checkout",
    path: "/checkout",
    meta: indexraiq4bUafSMeta || {},
    component: () => import("/vercel/path0/pages/checkout/index.vue")
  },
  {
    name: "checkout-order-pay-order_id",
    path: "/checkout/order-pay/:order_id()",
    meta: _91order_id_934QhPERmQPzMeta || {},
    component: () => import("/vercel/path0/pages/checkout/order-pay/[order_id].vue")
  },
  {
    name: "checkout-order-received-order_id",
    path: "/checkout/order-received/:order_id()",
    meta: _91order_id_93RMTRWWWPvIMeta || {},
    component: () => import("/vercel/path0/pages/checkout/order-received/[order_id].vue")
  },
  {
    name: "hfd-send-orders",
    path: "/hfd-send-orders",
    component: () => import("/vercel/path0/pages/hfd-send-orders.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexe9Brt5DfdhMeta || {},
    component: () => import("/vercel/path0/pages/index.vue")
  },
  {
    name: "my-account",
    path: "/my-account",
    component: () => import("/vercel/path0/pages/my-account/index.vue")
  },
  {
    name: "my-account-login",
    path: "/my-account/login",
    component: () => import("/vercel/path0/pages/my-account/login.vue")
  },
  {
    name: "my-account-lost-password",
    path: "/my-account/lost-password",
    component: () => import("/vercel/path0/pages/my-account/lost-password.vue")
  },
  {
    name: "product-product",
    path: "/product/:product(.*)*",
    meta: _91_46_46_46product_93SBP4NZMwiSMeta || {},
    component: () => import("/vercel/path0/pages/product/[...product].vue")
  },
  {
    name: component_45stub5X4Ei38PMgMeta?.name,
    path: "/policy",
    component: component_45stub5X4Ei38PMg
  },
  {
    name: component_45stub5X4Ei38PMgMeta?.name,
    path: "/how-it-works",
    component: component_45stub5X4Ei38PMg
  },
  {
    name: component_45stub5X4Ei38PMgMeta?.name,
    path: "/testimonials",
    component: component_45stub5X4Ei38PMg
  },
  {
    name: component_45stub5X4Ei38PMgMeta?.name,
    path: "/?post_type=product&p=33279",
    component: component_45stub5X4Ei38PMg
  },
  {
    name: component_45stub5X4Ei38PMgMeta?.name,
    path: "/?post_type=product&p=33059",
    component: component_45stub5X4Ei38PMg
  },
  {
    name: component_45stub5X4Ei38PMgMeta?.name,
    path: "/product/3d-peugeot-3008-2016-present",
    component: component_45stub5X4Ei38PMg
  },
  {
    name: component_45stub5X4Ei38PMgMeta?.name,
    path: "/product/classic-peugeot-3008-2016-present",
    component: component_45stub5X4Ei38PMg
  },
  {
    name: component_45stub5X4Ei38PMgMeta?.name,
    path: "/product/pvc-toyota-land-cruiser-2003-2009-3doors",
    component: component_45stub5X4Ei38PMg
  },
  {
    name: component_45stub5X4Ei38PMgMeta?.name,
    path: "/product/pvc-seres-5-2024-present",
    component: component_45stub5X4Ei38PMg
  },
  {
    name: component_45stub5X4Ei38PMgMeta?.name,
    path: "/product/leved-seres-5-2024-present",
    component: component_45stub5X4Ei38PMg
  },
  {
    name: component_45stub5X4Ei38PMgMeta?.name,
    path: "/product/leved-seres-5-2023-2024",
    component: component_45stub5X4Ei38PMg
  },
  {
    name: component_45stub5X4Ei38PMgMeta?.name,
    path: "/product/premium-bmw-x3-2020-present",
    component: component_45stub5X4Ei38PMg
  },
  {
    name: component_45stub5X4Ei38PMgMeta?.name,
    path: "/product/pvc-seres-5-2023-2024",
    component: component_45stub5X4Ei38PMg
  },
  {
    name: component_45stub5X4Ei38PMgMeta?.name,
    path: "/product/premium-toyota-land-cruiser-2003-2009-5doors",
    component: component_45stub5X4Ei38PMg
  },
  {
    name: component_45stub5X4Ei38PMgMeta?.name,
    path: "/product/pvc-bmw-x3-2020-present",
    component: component_45stub5X4Ei38PMg
  },
  {
    name: component_45stub5X4Ei38PMgMeta?.name,
    path: "/product/premium-bmw-x3-2017-present",
    component: component_45stub5X4Ei38PMg
  },
  {
    name: component_45stub5X4Ei38PMgMeta?.name,
    path: "/product/3d-toyota-land-cruiser-2003-2009-5doors",
    component: component_45stub5X4Ei38PMg
  },
  {
    name: component_45stub5X4Ei38PMgMeta?.name,
    path: "/product/classic-seres-5-2023-present",
    component: component_45stub5X4Ei38PMg
  },
  {
    name: component_45stub5X4Ei38PMgMeta?.name,
    path: "/product/3d-mg-mg5-2023-present",
    component: component_45stub5X4Ei38PMg
  },
  {
    name: component_45stub5X4Ei38PMgMeta?.name,
    path: "/product/premium-seres-5-2023-2024",
    component: component_45stub5X4Ei38PMg
  },
  {
    name: component_45stub5X4Ei38PMgMeta?.name,
    path: "/product/premium-seres-5-2024-present",
    component: component_45stub5X4Ei38PMg
  },
  {
    name: component_45stub5X4Ei38PMgMeta?.name,
    path: "/product/premium-ford-mustang-2021-present-electrical/",
    component: component_45stub5X4Ei38PMg
  },
  {
    name: component_45stub5X4Ei38PMgMeta?.name,
    path: "/product/premium-volkswagen-amarok-2010-present/",
    component: component_45stub5X4Ei38PMg
  },
  {
    name: component_45stub5X4Ei38PMgMeta?.name,
    path: "/product/pvc-chevrolet-suburban-2015-present/",
    component: component_45stub5X4Ei38PMg
  },
  {
    name: component_45stub5X4Ei38PMgMeta?.name,
    path: "/product/premium-toyota-land-cruiser-2003-2009-3doors",
    component: component_45stub5X4Ei38PMg
  },
  {
    name: component_45stub5X4Ei38PMgMeta?.name,
    path: "/product/classic-peugeot-3008-2016-present/",
    component: component_45stub5X4Ei38PMg
  },
  {
    name: component_45stub5X4Ei38PMgMeta?.name,
    path: "/product/pvc-mini-one-2001-2007-5doors",
    component: component_45stub5X4Ei38PMg
  },
  {
    name: component_45stub5X4Ei38PMgMeta?.name,
    path: "/product/3d-bmw-x3-2017-present",
    component: component_45stub5X4Ei38PMg
  },
  {
    name: component_45stub5X4Ei38PMgMeta?.name,
    path: "/product/classic-seres-5-2024-present",
    component: component_45stub5X4Ei38PMg
  },
  {
    name: component_45stub5X4Ei38PMgMeta?.name,
    path: "/product/premium-skoda-kodiaq-2017-present/",
    component: component_45stub5X4Ei38PMg
  },
  {
    name: component_45stub5X4Ei38PMgMeta?.name,
    path: "/product/premium-peugeot-3008-2016-present",
    component: component_45stub5X4Ei38PMg
  },
  {
    name: component_45stub5X4Ei38PMgMeta?.name,
    path: "/product/premium-seres-5-2023-present",
    component: component_45stub5X4Ei38PMg
  },
  {
    name: component_45stub5X4Ei38PMgMeta?.name,
    path: "/product/leved-bmw-x3-2020-present",
    component: component_45stub5X4Ei38PMg
  },
  {
    name: component_45stub5X4Ei38PMgMeta?.name,
    path: "/product/premium-subaru-forester-2019-present/",
    component: component_45stub5X4Ei38PMg
  },
  {
    name: component_45stub5X4Ei38PMgMeta?.name,
    path: "/product/3d-bmw-x3-2020-present-plug-in-hybrid",
    component: component_45stub5X4Ei38PMg
  },
  {
    name: component_45stub5X4Ei38PMgMeta?.name,
    path: "/product/premium-toyota-land-cruiser-2009-present-5doors-5places/",
    component: component_45stub5X4Ei38PMg
  },
  {
    name: component_45stub5X4Ei38PMgMeta?.name,
    path: "/product/premium-toyota-land-cruiser-2009-present-5doors-7places/",
    component: component_45stub5X4Ei38PMg
  },
  {
    name: component_45stub5X4Ei38PMgMeta?.name,
    path: "/product/premium-ssangyoung-korando-2010-2019",
    component: component_45stub5X4Ei38PMg
  },
  {
    name: component_45stub5X4Ei38PMgMeta?.name,
    path: "/product/premium-chevrolet-suburban-2015-present/",
    component: component_45stub5X4Ei38PMg
  },
  {
    name: component_45stub5X4Ei38PMgMeta?.name,
    path: "/product/pvc-mini-cabrio-convertible-2016-present/",
    component: component_45stub5X4Ei38PMg
  },
  {
    name: component_45stub5X4Ei38PMgMeta?.name,
    path: "/product/classic-dodge-journey-2008-present/",
    component: component_45stub5X4Ei38PMg
  },
  {
    name: component_45stub5X4Ei38PMgMeta?.name,
    path: "/product/leved-ssangyoung-rexton-2017-present/",
    component: component_45stub5X4Ei38PMg
  },
  {
    name: component_45stub5X4Ei38PMgMeta?.name,
    path: "/product/premium-ssangyoung-korando-1996-2006/",
    component: component_45stub5X4Ei38PMg
  },
  {
    name: component_45stub5X4Ei38PMgMeta?.name,
    path: "/product/premium-ssangyoung-korando-2010-2019/",
    component: component_45stub5X4Ei38PMg
  },
  {
    name: component_45stub5X4Ei38PMgMeta?.name,
    path: "/product/leved-ssangyoung-tivoli-2015-present",
    component: component_45stub5X4Ei38PMg
  },
  {
    name: component_45stub5X4Ei38PMgMeta?.name,
    path: "/product/leved-ssangyoung-rodius-2012-present/",
    component: component_45stub5X4Ei38PMg
  },
  {
    name: component_45stub5X4Ei38PMgMeta?.name,
    path: "/product/pvc-ssangyoung-actyon-2005-2009",
    component: component_45stub5X4Ei38PMg
  },
  {
    name: component_45stub5X4Ei38PMgMeta?.name,
    path: "/product/classic-mini-one-2014-present-3doors",
    component: component_45stub5X4Ei38PMg
  },
  {
    name: component_45stub5X4Ei38PMgMeta?.name,
    path: "/product/leved-mini-one-2001-2007-5doors",
    component: component_45stub5X4Ei38PMg
  },
  {
    name: component_45stub5X4Ei38PMgMeta?.name,
    path: "/product/premium-mini-one-2007-2014-5doors",
    component: component_45stub5X4Ei38PMg
  },
  {
    name: component_45stub5X4Ei38PMgMeta?.name,
    path: "/product/pvc-mini-one-2001-2007-3doors",
    component: component_45stub5X4Ei38PMg
  },
  {
    name: component_45stub5X4Ei38PMgMeta?.name,
    path: "/product/premium-ssangyoung-rexton-2002-2017/",
    component: component_45stub5X4Ei38PMg
  }
]